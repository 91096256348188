export default {
  redirect (url, params) {
    window.location.href = url + this.buildQueryString(params)
  },
  buildQueryString (params) {
    if (!params) return ''
    const args = Object.keys(params).map(p =>
      [p, params[p]]
        .map(encodeURIComponent)
        .join('=')
    )
    return '?' + args.join('&')
  },
  setLinkAttributes(link) {
    if (link.host !== window.location.hostname) {
      link.setAttribute('target', '_blank')
      link.setAttribute('rel', 'noopener noreferrer')
    }
  },
  updateAllExternalLinksInDocument() {
    const links = document.querySelectorAll('a')
    links.forEach(this.setLinkAttributes)
  },
  updateAllExternalLinksInString(htmlString) {
    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlString, 'text/html')
    const links = doc.querySelectorAll('a')
    links.forEach(this.setLinkAttributes)
    return doc.body.innerHTML
  }
}

// The Fisher-Yates algorithm
// @see https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle
export const shuffleArray = array => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
}
