<template>
  <div class="contribution">
    <div v-if="showStatus">
      <span :class="['pill', 'pill-' + contribution.contributionItem.status.toLowerCase()]">{{ contribution.contributionItem.status }}</span>
    </div>
    <h2>{{ contributionDate }}</h2>
    <h3>{{ contribution.contributionItem.location }}</h3>
    <div class="content" v-html="contribution.contributionItem.content"></div>
  </div>
</template>

<script>
export default {
  props: {
    contribution: Object,
    showStatus: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    contributionDate() {
      if (this.contribution.contributionItem && this.contribution.contributionItem.contribution_date) {
        return this.contribution.contributionItem.contribution_date
      }

      if (!this.contribution.contributionItem || !this.contribution.contributionItem.date) {
        return ''
      }

      // Some forms send data as UK format 'DD/MM/YYYY'
      if (this.contribution.contributionItem.date.indexOf('/') !== -1) {
        return this.contribution.contributionItem.date
      }

      return new Date(Date.parse(this.contribution.contributionItem.date))
    }
  }
}
</script>

<style scoped lang="scss">
.contribution {
  margin-bottom: 1rem;

  .content {
    margin-top: 33px;
  }
}
</style>
