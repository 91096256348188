<template>
  <div>
    <div v-for="(block, index) in blocks" :id="anchor(block)" :key="index" class="block">
      <SliderLoader v-if="block.type === 'slider'" :id="block.data.slider.id" />
      <p v-else-if="block.type === 'paragraph'" v-html="block.data.text"></p>
      <template v-else-if="block.type === 'header'">
        <h1 v-if="block.data.level === 1" v-html="block.data.text"></h1>
        <h2 v-if="block.data.level === 2" v-html="block.data.text"></h2>
        <h3 v-if="block.data.level === 3" v-html="block.data.text"></h3>
        <h4 v-if="block.data.level === 4" v-html="block.data.text"></h4>
        <h5 v-if="block.data.level === 5" v-html="block.data.text"></h5>
      </template>

      <hr v-else-if="block.type === 'delimiter'" class="delimiter" />
      <div v-else-if="block.type === 'raw'" v-html="block.data.html" />

      <div v-else-if="block.type === 'quote'" class="quote-block">
        <PostQuoteSection
          :body="block.data.text"
          :author="block.data.caption"
          theme="keystone"
        />
      </div>

      <template v-else-if="block.type === 'list'">
        <ol v-if="block.data.style === 'ordered'">
          <li v-for="(item, rowIndex) in block.data.items" :key="rowIndex" v-html="item"></li>
        </ol>
        <ul v-else>
          <li v-for="(item, rowIndex) in block.data.items" :key="rowIndex" v-html="item"></li>
        </ul>
      </template>

      <div v-else-if="block.type === 'image'" class="image-block">
        <template v-if="link(block)">
          <a :href="link(block)" target="_blank">
          <img :src="block.data.file.url" :alt="block.data.caption"
            :classes="{ 'img-with-border': block.data.withBorder, 'img-with-background': block.data.withBackground, 'img-stretched': block.data.stretched }">
          </a>
          <cite v-if="block.data.caption" v-html="block.data.caption"></cite>
        </template>
        <template v-else>
          <img :src="block.data.file.url" :alt="block.data.caption"
            :classes="{ 'img-with-border': block.data.withBorder, 'img-with-background': block.data.withBackground, 'img-stretched': block.data.stretched }">
          <cite v-if="block.data.caption" v-html="block.data.caption"></cite>
        </template>
      </div>

      <div v-else-if="block.type === 'simpleImage'" class="image-block">
        <template v-if="link(block)">
          <a :href="link(block)" target="_blank">
          <img :src="block.data.url" :alt="block.data.caption"
            :classes="{ 'img-with-border': block.data.withBorder, 'img-with-background': block.data.withBackground, 'img-stretched': block.data.stretched }">
          <cite v-if="block.data.caption" v-html="block.data.caption"></cite>
          </a>
        </template>
        <template v-else>
          <img :src="block.data.url" :alt="block.data.caption"
            :classes="{ 'img-with-border': block.data.withBorder, 'img-with-background': block.data.withBackground, 'img-stretched': block.data.stretched }">
          <cite v-if="block.data.caption" v-html="block.data.caption"></cite>
        </template>
      </div >

      <template v-else-if="block.type === 'embed'">
        <div class="embed">
          <div v-if="block.data.service === 'youtube'" class="responsive responsive-16by9"><iframe allowfullscreen="" :src="block.data.embed" class="embed-tool__content" :width="block.data.width" :height="block.data.height" frameborder="0"></iframe></div>
          <div v-if="block.data.service === 'vimeo'" class="responsive responsive-16by9"><iframe :src="block.data.embed" class="embed-tool__content" :width="block.data.width" :height="block.data.height" frameborder="0"></iframe></div>
          <div v-if="block.data.service === 'fba' || block.data.service === 'fba-embed'" class="responsive responsive-16by9"><iframe  width="560" height="315" :src="block.data.embed" title="Free Buddhist Audio : Talk" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
          <div v-if="block.data.service === 'soundcloud'" class="responsive responsive-16by9"><iframe :src="block.data.embed" width="100%" height="400" scrolling="no" frameborder="no" class="embed-tool__content"></iframe></div>
          <cite v-if="block.data.caption" v-html="block.data.caption"></cite>
        </div>
      </template>

      <template v-else-if="block.type === 'table'">
        <table>
          <thead v-if="block.data.withHeadings">
            <tr v-for="(row, rowIndex) in block.data.content.slice(0, 1)" :key="rowIndex">
              <th v-for="(cell, cellIndex) in row" v-html="cell" :key="index + '-' + cellIndex"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, bodyRowIndex) in block.data.content.slice(1)" :key="bodyRowIndex">
              <td v-for="(cell, cellIndex) in row" v-html="cell" :key="index + '-' + cellIndex"></td>
            </tr>
          </tbody>
        </table>
      </template>

      <FindACentre v-else-if="block.type === 'findCentre'" />

      <template v-else-if="block.type === 'form'">
        <ShabdaForm v-if="block.data.selectedForm === 'ShabdaForm'" />
      </template>

      <template v-else-if="block.type === 'vuejsComponent'">
        <ShabdaContributions v-if="block.data.component === 'ShabdaContributions'" />
      </template>

      <div v-else>Cannot handle {{ block.type }} yet. <pre>{{ block }}</pre></div>
    </div>
  </div>
</template>

<script>
import SliderLoader from '../components/SliderLoader'
import FindACentre from '../components/FindACentre'
import ShabdaForm from '../components/forms/ShabdaForm'
import ShabdaContributions from '../components/organisms/ShabdaContributions'
import PostQuoteSection from '@dharmachakra/tbco-shared-styles/src/components/PostQuoteSection'

export default {
  components: {
    SliderLoader,
    FindACentre,
    ShabdaForm,
    ShabdaContributions,
    PostQuoteSection
  },
  props: {
    blocks: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    hasAnchor(block) {
      return block.tunes && block.tunes.anchorTune && block.tunes.anchorTune.anchor
    },
    anchor(block) {
      return (block.tunes && block.tunes.anchorTune) ? block.tunes.anchorTune.anchor : null
    },
    link(block) {
      return (block.tunes && block.tunes.linkTune) ? block.tunes.linkTune.link : null
    }
  }
}
</script>

<style scoped lang="scss">
p {
  margin: 0.5rem 0 1rem 0;
}

.embed {
    width: 720px;
    max-width: 100%;
    min-width: 320px;
    margin-bottom: 1.5rem;
}

.responsive {
    position: relative;
    height: 0;
    overflow: hidden;
    background-color: #f0f0f0;

    img, iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.responsive-16by9 {
    padding-bottom: 56.25%;
}

.delimiter {
  background-color: rgba(151,151,151, 1);
  height: 1px;
  width: 260px;
  margin: 3.5rem auto;
}

.image-block, .quote-block {
  margin-bottom: 1.5rem;
}
</style>
