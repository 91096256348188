<template>
  <div class="confirm-action">
    <button v-if="confirmed" type="button" @click="answer" :class="btnStyles">
      <slot name="confirm" @click="confirmWithdraw = false" />
    </button>
    <button v-else type="button" @click="answer" :class="btnStyles">
      <slot />
    </button>
    <a v-if="confirmed" href="confirm-answer-cancel" @click.prevent="confirmed = false" class="cancel">cancel [x]</a>
  </div>
</template>

<script>
export default {
  props: {
    btnStyles: String
  },
  data() {
    return {
      confirmed: false
    }
  },
  methods: {
    answer() {
      if (!this.confirmed) {
        this.confirmed = true
        return
      }

      this.$emit('confirmed')
    }
  }
}
</script>

<style scoped lang="scss">
.cancel {
  margin-left: 0.5rem;
}

.confirm-action {
  display: inline;
}
</style>
