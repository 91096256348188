<template>
  <div class="flex">
    <ValidationObserver ref="form">
    <form ref="contributeForm" @submit.prevent="onSubmit" method="POST" class="contribute-form">
      <input type="hidden" name="id" v-model="form_id" />
      <input type="hidden" name="contribution_type" v-model="form_contribution_type" />

      <div class="form_input">
        <label>For</label>
        <ValidationProvider name="eulogy_for" rules="required|max:255" v-slot="{ errors }">
        <input type="text" name="eulogy_for" v-model="formData.eulogy_for" placeholder="Who is the eulogy for?" @input="persist" />
        <span>{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="form_input">
        <label class="sr-only">Content</label>
        <ValidationProvider name="content" rules="required" v-slot="{ errors }">
        <Editor v-model="formData.content" @input="persist" />
        <span>{{ errors[0] }}</span>
        </ValidationProvider>
      </div>

      <p>You have submitted {{ wordCount(formData.content).words }} words</p>

      <div v-if="showAdminOptions" class="admin-options">
        <h3 class="title">Admin options</h3>
        <div class="form_input">
          <label>Author</label>
          <p>Choose an author for this contribution.</p>
          <ValidationProvider name="author" rules="" v-slot="{ errors }">
          <input type="text" name="author" v-model="form_author" placeholder="Enter ordername" />
          <span>{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>

      <p>
      <button type="submit" class="btn btn-success btn-action">preview and submit</button>
      </p>
    </form>
    </ValidationObserver>
  </div>
</template>

<script>
import Editor from '../Editor.vue'
import store from '../../store/shabda'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEnvelopeOpen, faExclamation, faCommentAlt } from '@fortawesome/free-solid-svg-icons'
import { wordCount } from '../../utils/string-utils'

library.add(faEnvelopeOpen, faExclamation, faCommentAlt)

export default {
  components: {
    Editor,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    id: String,
    author: String,
    label: String,
    content: String,
    eulogy_for: String,
    showAdminOptions: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form_id: this.id,
      form_author: this.author,
      form_eulogy_for: this.eulogy_for,
      form_contribution_date: this.contribution_date,
      form_label: this.label,
      form_contribution: this.content,
      form_contribution_type: 'eulogy'
    }
  },
  computed: {
    formData() {
      return store.contributionForm
    }
  },
  methods: {
    persist() {
      store.persist()
    },
    onSubmit() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return
        }

        this.$emit('send')

        this.$nextTick(() => {
          this.$refs.form.reset()
        })
      })
    },
    wordCount(htmlString) {
      return wordCount(htmlString)
    }
  }
}
</script>
