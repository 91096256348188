<template>
  <div class="contribution">
    <div v-if="showStatus">
      <span :class="['pill', 'pill-' + contribution.contributionItem.status.toLowerCase()]">{{ contribution.contributionItem.status }}</span>
    </div>
    <h3>{{ contribution.contributionItem.eulogy_for }}</h3>
    <div v-html="contribution.contributionItem.content"></div>
  </div>
</template>

<script>
export default {
  props: {
    contribution: Object,
    showStatus: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped lang="scss">
.contribution {
  margin-bottom: 1rem;
}
</style>
