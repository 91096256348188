<template>
  <div class="page">
    <div class="t-responsive-frontpage">
      <SiteHeader
          :username="tbcoUserView ? tbcoUserView.username : undefined"
          :displayName="tbcoUserView ? tbcoUserView.trimmed_name : undefined"
          :loggedIn="(tbcoUserView && tbcoUserView.isLoggedIn) ? true : false"
          :whatsNewCount="tbcoUserView ? parseInt(tbcoUserView.whats_new_count) : undefined"
          :canOptIntoTrial="tbcoUserView ? tbcoUserView.mobile_trial : undefined"
          :optedIntoTrial="tbcoUserView ? tbcoUserView.show_responsive_pages : undefined"
          :userProfileImage="tbcoUserView ? tbcoUserView.user_profile_image : undefined"
          :orderMember="isOrderMember"
          :orderBadge="isOrderMember ? true : false"
          :siteAdmin="isSiteAdmin"
          :sliderEdit="sliderEdit"
          :futureDharmaDonation="futureDharmaDonation"
          :bannerTitle="bannerTitle"
          :bannerSubtitle="bannerSubtitle"
          :headerBannerVideo="headerBannerVideo"
          :siteHeaderMenu="topMenuItems"
          :showBanner="showBanner"
          @donate="donate"
          :loginUrl="loginUrl"
          :userMenu="userMenu"
          toggleLabel="Explore"
          toggleLabelExpanded="Triratna"
          searchPrefixUrl="/search/"
          :fullSearchMenu="true"
          :showBetaLabel="true"
        />
    </div>

    <div v-if="appMessages" class="app-messages">
        <SystemMessages :messages="appMessages"
          @clearSystemAlerts="clearAppMessages"
        />
    </div>

    <slot name="page-menu" />
    <slot name="header" />
    <slot />
    <slot name="footer" />

    <div class="t-responsive-frontpage">
      <Sidemenu :menuItems="sidemenuItems" />
    </div>
  </div>
</template>

<script>
import SiteHeader from '@dharmachakra/tbco-site-header/src/components/SiteHeader.vue'
import SiteHeaderMenuApi from '@dharmachakra/tbco-site-header/src/api/SiteHeaderMenuApi'
import Sidemenu from '@dharmachakra/tbco-sidemenu/src/components/Sidemenu.vue'
import SystemMessages from '../components/organisms/SystemMessages.vue'
import appApi from '../api/app-api.js'

import '@dharmachakra/tbco-shared-styles/src/assets/scss/modules/_reset.scss'
import '@dharmachakra/tbco-shared-styles/src/assets/scss/tbco-shared-styles.scss'
import '@dharmachakra/tbco-shared-styles/src/assets/scss/themes/_themes.scss'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlay } from '@fortawesome/free-solid-svg-icons'

library.add(faPlay)

export default {
  components: {
    SiteHeader,
    Sidemenu,
    SystemMessages
  },
  data() {
    return {
      bannerTitle: '',
      bannerSubtitle: '',
      headerBannerVideoURL: '',
      topMenuItems: {},
      topMenu: {},
      exploreMenu: {},
      sidemenuItems: [],
      tbcoUserView: null,
      isOrderMember: false,
      isSiteAdmin: false,
      futureDharmaDonation: false,
      showBanner: false,
      headerBannerVideo: '',
      sliderEdit: false
    }
  },
  created() {
    if (document.__TBCO__.app && document.__TBCO__.app.menus) {
      this.topMenuItems = this.convertMenuStructure(document.__TBCO__.app.menus.top) || {}
      this.topMenu = document.__TBCO__.app.menus.top || {}
      this.exploreMenu = document.__TBCO__.app.menus.explore || {}
      this.sidemenuItems = (this.convertSidemenuStructure(document.__TBCO__.app.menus.top.menu_items, ['top-menu']) || [])
        .concat([{
          label: 'explore <span class="sidemenu-link-bold">triratna</span>',
          classes: ['explore-triratna']
        }])
        .concat(
          (this.convertSidemenuStructure(document.__TBCO__.app.menus.explore.menu_items) || [])
        )
    }

    this.tbcoUserView = document.__TBCO__.userView
    this.isOrderMember = document.__TBCO__.isOrderMember
    this.isSiteAdmin = document.__TBCO__.isSiteAdmin
    this.sliderEdit = document.__TBCO__.sliderEdit
    this.futureDharmaDonation = document.__TBCO__.futureDharmaDonationBanner
    this.showBanner = document.__TBCO__.showBanner
    this.headerBannerVideo = document.__TBCO__.headerBannerVideoURL
    this.bannerTitle = document.__TBCO__.bannerTitle
    this.bannerSubtitle = document.__TBCO__.bannerSubtitle
    SiteHeaderMenuApi.loadItems(document.__TBCO__.siteHeaderMenu)
    document.__TBCO__.siteHeaderMenuApi = SiteHeaderMenuApi
  },
  computed: {
    loginUrl() {
      return '/user/login?destination=' + window.location.pathname + window.location.search
    },
    appMessages() {
      return appApi.messages()
    },
    userMenu() {
      const username = this.tbcoUserView ? this.tbcoUserView.username : undefined
      const whatsNewCount = this.tbcoUserView ? parseInt(this.tbcoUserView.whats_new_count) : undefined

      let items = [
        {
          link: '/toggle-new-bco?returnTo=' + encodeURIComponent(window.location.href),
          label: 'display old website'
        }
      ]

      if (this.isOrderMember) {
        items.push({
          link: '/stories/order',
          label: 'order website',
          classes: ['order-only']
        })
        items.push({
          link: '/users/' + username + '/order_edit?destination=users/' + username + '/order',
          label: 'edit my order account',
          classes: ['order-only']
        })
      }

      items.push({
        link: '/contact-us-give-feedback',
        label: 'support & feedback'
      })

      items.push({
        link: '/users/' + username + '/edit',
        label: 'my profile'
      })

      items = items.concat([{
        link: '/users/' + username + '/edit',
        label: 'add/edit my picture'

      },
      {
        link: '/mysangha/new',
        label: 'my sangha ' + ((whatsNewCount > 0) ? '(' + whatsNewCount + ')' : ''),
        classes: ['my-sangha']
      },
      {
        link: '/projects/my_projects_groups/list/az',
        label: 'spaces I follow'
      },
      {
        link: '/users/' + username + '/my_posts',
        label: 'my posts'
      }
      ])

      if (this.isSiteAdmin && this.sliderEdit) {
        items.push({
          link: '/home-sliders/home-page-items-edit',
          label: 'edit home sliders',
          classes: ['admin-control']
        })
        items.push({
          link: '/node/add/home-media-panel',
          label: 'add to home page',
          classes: ['admin-control']
        })
      }

      if (this.isSiteAdmin) {
        items.push({
          link: '/admin/',
          label: 'drupal admin',
          classes: ['admin-control']
        })
        items.push({
          link: '/manager',
          label: 'manager',
          classes: ['admin-control']
        })
      }

      items.push({
        link: '/user/logout',
        label: 'log out'
      })

      return items
    },
    siteHeaderMenu () {
      return SiteHeaderMenuApi.store.siteHeaderMenu
    }
  },
  methods: {
    clearAppMessages() {
      appApi.clearMessages()
    },
    convertSidemenuStructure(menu, additionalClasses) {
      if (Array.isArray(menu)) {
        return menu.map(item => this.convertSidemenuStructure(item, additionalClasses))
      }

      if (menu.url) {
        menu.href = menu.url
        menu.link = menu.url
      }

      if (menu.name) {
        menu.label = menu.name
      }

      if (additionalClasses) {
        menu.classes = (menu.classes || []).concat(additionalClasses)
      }

      if (menu.om) {
        menu.omOnly = menu.om
        menu.classes = (menu.classes || []).concat(['order-only'])
      }

      if (menu.target && menu.target === '_blank') {
        menu.newTab = true
      }

      if (menu.menu_items) {
        menu.items = this.convertSidemenuStructure(menu.menu_items, additionalClasses)
        menu.children = this.convertSidemenuStructure(menu.menu_items, additionalClasses)
      }

      return menu
    },
    convertMenuStructure(menu) {
      if (Array.isArray(menu)) {
        return menu.map(item => this.convertMenuStructure(item))
      }

      if (menu.url) {
        menu.href = menu.url
        menu.link = menu.url
      }

      if (menu.om) {
        menu.class = 'order-only'
      }

      menu.newTab = (menu.target && menu.target === '_blank')

      if (menu.menu_items) {
        menu.items = this.convertMenuStructure(menu.menu_items)
        menu.subMenu = this.convertMenuStructure(menu.menu_items)
      }

      return menu
    },
    donate() {
      if (document.__TBCO__.donationAction) {
        document.__TBCO__.donationAction()
      }
    }
  }
}
</script>
<style lang="scss">
a {
  color: black;
}

.standout {
  border-left: 8px solid #eee;
  margin: 1rem 0;
  padding: 0.5rem 1rem;
}

.story-link {
  text-decoration: none;
}

.page .soundcloud-feature-wrapper {
  background-image: url("../images/soundcloud-embed-bg.jpg");
  background-size: contain;
  background-color: #f3f3f3;
  background-repeat: no-repeat;
  background-position: center;
}

.hooper-single-slider-wrapper, .hooper-slider-wrapper {
  touch-action: pan-y;
}

.responsive {
    position: relative;
    height: 0;
    overflow: hidden;
    background-color: #f0f0f0;

    img, iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.responsive-16by9 {
    padding-bottom: 56.25%;
}

.c-user-label-box__label-container {
  overflow: hidden;
}

.page .hooper-navigation {
  .hooper-prev, .hooper-next {
    top: 50%;
  }
}

// overrides
.page .header-notice-bar .center-group {
  text-align: center;
}

.t-responsive-frontpage .sidemenu-toggle-button .title-menu {
  @media screen and (max-width: 950px) {
    display: none;
  }
}

.newsletter-signup .hide-link {
  display: none;
}

.t-group .c-channel-hero__image-container {
  margin-top: 1.75rem;
}

.t-responsive-frontpage .header-bar .logo .title-logo {
  margin-right: 0 !important;
}

.t-project .c-footer-links {
  background-color: #e4611d;
}

.page .t-project .c-footer-links__title {
  margin-left: 0.5rem;
}

@media screen and (max-width: 750px) {
  .page .t-project .c-channel-hero__image-container {
    margin-top: -1.3rem;
  }
}

@media screen and (max-width: 768px) {
  .page .t-project .c-footer-links__title {
    font-size: 26px;
    line-height: 1.2;
  }
}

@media screen and (max-width: 768px) {
  .hooper-slider-section.single-slider .hooper-slide-inner .single-slide-info h4 {
      overflow: unset !important;
      display: block !important;
  }
}

.hooper-slider-section.single-slider .hooper-navigation {
  display: block !important;
}
</style>

<style scoped lang="scss">
@import '../scss/partials/_colours.scss';
@import '../scss/modules/_fonts.scss';

.page {
  width: 100%;
  max-width: 1440px;
  padding: 0;
  margin: 1rem auto 0 auto;
  position: relative;

  @media screen and (max-width: 750px) {
    margin: 0 auto;
  }
}

.footer {
  display: flex;
  gap: 1rem;

  > div {
    flex-basis: calc(100% / 6);
  }
}

::v-deep {
  // Order menu items.
  .header-menu .menu-header > li.order-only, .user-profile-menu .order-only {
    color: $order-blue;

    .sub.menu-link-header {
      color: $order-blue;
    }
  }

  .header-menu .menu-header > li.order-only:hover .fa-caret-down {
    color: $order-blue;
  }

  .header-menu .menu-header > li.order-only .sub.menu-link-header :hover, .t-responsive-frontpage .user-profile-menu .order-only:hover {
    background-color: $order-blue;
    color: #fff;
  }

  .t-responsive-frontpage #sidemenu ul.links li.order-only a {
    color: $order-blue;

    li a {
      color: $order-blue;
    }
  }

  #sidemenu {
    .links > li:first-child a {
      padding-left: 0 !important;
    }

    .links > li a {
      .sidemenu-link-bold {
        font-weight: 700;
      }
    }

    .top-menu {
      a {
        padding-left: 0 !important;
      }

      @media screen and (min-width: 768px) {
        display: none;
      }
    }

    .explore-triratna {
      position: relative;

      a {
        font-weight: 700;
        color: #000;
        cursor: default;

        &::before {
          content: '\2022';
          display: inline-block;
          position: absolute;
          left: 1.75rem;
          top: 0.4rem;
          font-size: 32px;
          display: none;
        }

        &:hover {
          background-color: #fff;
        }
      }

      @media screen and (min-width: 768px) {
        display: none;
      }
    }
  }
}

::v-deep .topmenu-search-box {
  font-family: "Source Sans Pro", sans-serif;

  .button-close {
    border: none;
    font-family: "Source Sans Pro", sans-serif;
    top: 0.6rem;
    cursor: pointer;
  }
}
</style>
