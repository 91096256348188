import utils, { shuffleArray } from './utils'
import { ajaxRequest } from '../utils/http.js'

const entity = 'sliders'

export const buildSlideBody = (data, random) => {
  if (random) {
    shuffleArray(data)
  }

  return data.map(pair => {
    let body = ''

    if (!pair.data || !pair.type) {
      return {}
    }

    switch (pair.type) {
      case 'image':
        body = '<img src="' + pair.data.image + '" />'
        break
      case 'embed':
        if (pair.data.embed_code) {
          body = pair.data.embed_code
        }

        if (pair.data.embed_url) {
          body = '<iframe src="' + pair.data.embed_url + '" width="100%" height="100%"/></iframe>'
        }
        break
      case 'quote':
        body = '<div class="c-post-quote__custom_html_slide" style="display: flex;"><div class="c-post-quote-wrapper">'
        if (pair.data.url) {
          body += '<div class="t-project"><div class="c-post-body-section__quote-body"><div class="post-content"><a href="' + pair.data.url + '" class="story-link" target="_blank">' + pair.data.text + '</a></div>'
          body += '<div class="c-post-body-section__quote-author"><a href="' + pair.data.url + '" class="story-link" target="_blank"><cite>' + pair.data.subtitle + '</cite></a></div></div></div></div>'
        } else {
          body += '<div class="t-project"><div class="c-post-body-section__quote-body"><div class="post-content">' + pair.data.text + '</div>'
          body += '<div class="c-post-body-section__quote-author"><cite>' + pair.data.subtitle + '</cite></div></div></div></div>'
        }
        if (pair.data.image && pair.data.url) {
          body += '<div class="c-post-body-section__quote-image"><a href="' + pair.data.url + '" style="width: 30%; min-width: 200px;"><img src="' + pair.data.image + '" /></a></div>'
        } else if (pair.data.image) {
          body += '<img src="' + pair.data.image + '" style="width: 30%; min-width: 200px;" />'
        }

        body += '</div>'
        break
      default:
        break
    }

    if (pair.type === 'quote') {
      return {
        body: pair.data.text,
        author: pair.data.subtitle,
        link: pair.data.url,
        image: pair.data.image,
        slideType: 'quote'
      }
    }

    return {
      title: (pair.type === 'quote') ? '' : pair.data.text,
      subtitle: pair.data.subtitle,
      link: pair.data.url,
      body: body,
      type: pair.type,
      slideColor: pair.data.slideColor
    }
  })
}

export default {
  fetchAll (pageNo, limit = 10, criteria = null) {
    const data = { limit: limit, pageNo: pageNo || 1 }
    if (criteria) {
      data.criteria = criteria
    }
    const url = '/v1/' + entity + utils.buildQueryString(data)
    return ajaxRequest(url, 'get').promise
  },
  fetch (id) {
    const url = '/v1/' + entity + '/' + id
    return ajaxRequest(url, 'get').promise
  },
  create (data) {
    const url = '/v1/' + entity
    return ajaxRequest(url, 'post', data).promise
  },
  update (data) {
    const url = '/v1/' + entity + '/' + encodeURIComponent(data.id)
    return ajaxRequest(url, 'put', data).promise
  },
  delete (id) {
    const url = '/v1/' + entity + '/' + id
    return ajaxRequest(url, 'delete').promise
  }
}
