<template>
  <div class="contribution">
    <div v-if="showStatus">
      <span :class="['pill', 'pill-' + contribution.contributionItem.status.toLowerCase()]">{{ contribution.contributionItem.status }}</span>
    </div>
    <h3>{{ contribution.contributionItem.title }}</h3>
    <div v-html="contribution.contributionItem.content"></div>
    <div v-if="contribution.contributionItem.include_om_photo">
      <h4>Additional information</h4>
        <p class="note">Include OM photo for {{ contribution.contributionItem.om_photo_name }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contribution: Object,
    showStatus: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped lang="scss">
.contribution {
  margin-bottom: 1rem;
}

.note {
  font-weight: 700;
}
</style>
