<template>
  <div class="community-guidelines">
    <h1>Community guidelines</h1>
    <br><p>Welcome to our new space for the whole Triratna Buddhist Community and anyone who wishes to connect with others in the spirit of the Dharma. We’re excited to see what directions the site takes – thanks for being part of it!</p>
    <br><p>Here are our community content guidelines, which are designed to help create a positive environment for everyone…</p>
    <br><p>1. Please be courteous at all times. If you’re engaged in any kind of discussion, please avoid swearing/cursing and be as prepared to listen as you are to express yourself. Remember that there’s always a real person behind a computer screen, and they are likely quite different from you!</p>
    <br><p>2. Think twice before posting anything that’s likely to give offence or be inflammatory. That doesn’t promote good conversation. If you’re upset at something you see here, perhaps let a little time pass before responding. Bear in mind this isn’t a space to vent our views, it’s about exploring respectfully with others what it means to be a Buddhist within our community and in the modern world generally. </p>
    <br><p>3. This site is intended for the discussion and sharing of Buddhist practice in the Triratna community. Please do not make posts or comments that are unrelated to the context you’re taking part in, are primarily commercial in nature, or advertising non-Triratna Dharma events. We may remove posts or comments that are considered off-topic. Please do not make posts or comments with, or linking to, gratuitously violent or explicitly sexual content on any space on the site. Please also abide by our position with regard to the posting of copyrighted material.</p>
    <br><p>4. Everyone has off-moments, and we’ll always try to be in friendly dialogue with you if a problem arises with one of your contributions. But we reserve the right to remove posts and comments (or even suspend user accounts) when we feel these guidelines are not observed. Needless to say, we won’t tolerate any kind of hate speech, ‘trolls’ or ‘flame wars’ here.</p>
    <br><p>5. Our current editorial policy around Safeguarding is aligned with the advice given by those tasked with developing Triratna’s approach to this important area of ethical life. If anyone breaches current policy by posting in ways that mean The Buddhist Centre Online potentially break the law by hosting the material, then we will have to remove their posts or comments. We respectfully request that all users bear this in mind when posting. If in doubt, please feel free to ask first before posting. It will save time, energy, and lead to less potential polarisation in these spaces, even ifthere is disagreement.</p>
    <br><p>Whatever you contribute we very much encourage you to think about it in the light of the Buddhist ethical precepts around ‘Right Speech’. These encourage communication that is: truthful, kindly and gracious, helpful and harmonious. We look forward to all you have to bring to the site!</p>
  </div>
</template>

<script>
export default {
  name: 'CommunityGuidelines'
}
</script>

<style scoped lang="scss">
@import "@dharmachakra/tbco-shared-styles/src/assets/scss/partials/_variables.scss";

.community-guidelines {
  font-family: $font-family-open-sans;
}

p {
  font-size: 20px;
  @media screen and (max-width: $mobile-max-size){
    font-size: 16px;
  }
}
</style>
