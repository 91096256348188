<template>
  <div>
    <component v-show="!edit"
      :is="contributionComponent"
      :key="contribution.id"
      :contribution="contribution"
    />

    <div v-if="showActions" class="contribution-actions btn-group">
      <button @click.prevent="(edit) ? store.clearEditContribution() : store.editContribution(contribution)" class="btn">
        <template v-if="edit">
          <font-awesome-icon class="icon" :icon="['fal', 'times']" />
          cancel
        </template>
        <template v-else>
        <font-awesome-icon class="icon" :icon="['fal', 'pencil-alt']" />
        amend
        </template>
      </button>
      <ConfirmAction
          v-if="!edit"
          btnStyles="btn btn-danger"
          @confirmed="withdrawContribution"
          >
          <template>
            <font-awesome-icon class="icon" :icon="['fas', 'trash-alt']" />
              withdraw
          </template>
      <template slot="confirm">
        <font-awesome-icon class="icon" :icon="['fas', 'trash-alt']" />
          Are you sure?
      </template>
      </ConfirmAction>
    </div>
  </div>
</template>

<script>
import ConfirmAction from './ConfirmAction.vue'
import shabdaApi from '../../api/shabda-api.js'
import store from '../../store/shabda.js'
import ShabdaLetterContribution from './ShabdaLetterContribution.vue'
import ShabdaNoticeContribution from './ShabdaNoticeContribution.vue'
import ShabdaEulogyContribution from './ShabdaEulogyContribution.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { faPencilAlt, faTimes } from '@fortawesome/pro-light-svg-icons'

library.add(faTrashAlt, faPencilAlt, faTimes)

export default {
  components: {
    ShabdaLetterContribution,
    ShabdaNoticeContribution,
    ShabdaEulogyContribution,
    ConfirmAction
  },
  props: {
    contribution: Object,
    showActions: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    store() {
      return store
    },
    edit() {
      return store.state.editingForm === this.contribution.contributionItem.id
    },
    contributionComponent() {
      if (this.contribution.contributionItem.type === 'letter') {
        return 'ShabdaLetterContribution'
      }

      if (this.contribution.contributionItem.type === 'notice') {
        return 'ShabdaNoticeContribution'
      }

      if (this.contribution.contributionItem.type === 'eulogy') {
        return 'ShabdaEulogyContribution'
      }

      return ''
    }
  },
  methods: {
    withdrawContribution() {
      shabdaApi.remove(this.contribution)
        .then(() => {
          this.$emit('withdrawn')
        })
        .catch(err => {
          console.error(err)
        })
    }
  }
}
</script>

<style scoped lang="scss">
.contribution-actions {
  margin-bottom: 2rem;
}
</style>
