import defaultProfileImage from './images/default-profile-image.png'

export default class {
  constructor () {
    if (!document.__TBCO__) {
      document.__TBCO__ = {}
    }
    this.loggedIn = document.__TBCO__.loggedIn
    if (this.loggedIn) {
      this.user = document.__TBCO__.user
    }
  }

  isLoggedIn () {
    return this.loggedIn
  }

  hasPermission (permissionName) {
    if (!this.isLoggedIn() || !this.user) {
      return false
    }

    return this.user.permissions
      .map(x => x.name)
      .some(x => x === permissionName)
  }

  hasRole(roleName) {
    if (!this.isLoggedIn() || !this.user) {
      return false
    }

    return this.user.roles
      .map(x => x.name)
      .some(x => x === roleName)
  }

  displayName () {
    if (!this.isLoggedIn() || !this.user) {
      return undefined
    }

    return this.user.name
  }

  username () {
    if (!this.isLoggedIn() || !this.user) {
      return undefined
    }

    return this.user.username
  }

  isOrderMember () {
    if (!this.isLoggedIn() || !this.user) {
      return false
    }

    return !!this.user.isOrderMember
  }

  profileImage () {
    if (!this.isLoggedIn() || !this.user) {
      return null
    }
    if (!this.user.profileImageUrl) {
      return defaultProfileImage
    }
    return this.user.profileImageUrl
  }

  isAdmin() {
    return (document.__TBCO__.userView && document.__TBCO__.userView.is_admin)
  }
}
