import Vue from 'vue'

export const ajaxRequest = (url, verb, data) => {
  let xhrRequest
  const reqPromise = new Promise((resolve, reject) => {
    const options = {
      'headers': {
        'Content-Type': 'application/json'
      },
      before(xhr) {
        xhrRequest = xhr
      }
    }

    const req = (data)
      ? Vue.http[verb](url, data, options)
      : Vue.http[verb](url, options)

    req.then(function (response) {
      resolve(response.data)
    }, function (response) {
      reject(response.data)
    })
  })

  return {
    xhrRequest: xhrRequest,
    promise: reqPromise
  }
}
