<template>
  <button class="c-comment-show-hide-button" v-on:click="handleClick" @click="$emit('toggle-comments')" >
    <div v-if="!isActive" class ="c-comment-show-hide-button__text">show conversation</div>
    <div v-if="isActive" class ="c-comment-show-hide-button__text">hide conversation</div>
  </button>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: this.isOpen
    }
  },
  methods: {
    handleClick() {
      this.isActive = !this.isActive
    }
  }
}
</script>

<style lang="scss">
@import "src/www/sites/all/themes/tbc/scss/_variables.scss";
@import "@dharmachakra/tbco-shared-styles/src/assets/scss/partials/_variables.scss";

.c-comment-show-hide-button{
  background-color:  rgba(151,151,151, 1);
  border: 1px solid rgba(151,151,151, 1);
  width: 220px;
  height: 40px;
  min-width: 190px;
  font-size: 16px;
  letter-spacing: 0.5px;
  @media screen and (width <= $mobile-max-size) {
    min-width: 177px;
    width: 177px;
  }
}

.c-comment-show-hide-button__text{
  color: rgba(255,255,255,1);
  font-family: $font-family-open-sans;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.63px;
  text-align: center;
  @media screen and (width < 721px) {
    font-size: 16px;
    letter-spacing: 0.5px;
  }
}

</style>
