<template>
  <div class="load-more-container">
    <div v-if="icon && loading" class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    <p v-if="!icon && loading" class="load-more">{{ loadingMsg }}</p>
  </div>
</template>

<script>
export default {
  props: {
    msg: {
      type: String,
      default: 'loading'
    },
    loading: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loadingMsg: this.msg,
      interval: null
    }
  },
  mounted() {
    if (this.loading && !this.icon) {
      this.interval = setInterval(() => {
        this.loadingMsg = this.loadingMsg + '.'
      }, 400)
    }
  }
}
</script>

<style scoped lang="scss">
.load-more-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.load-more {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 26px;
  letter-spacing: 0.77px;
  margin: 4rem 0;
  text-align: center;

  a {
    color: #000;
  }

  a:hover {
    text-decoration: underline;
  }

  a:active {
    color: #000;
  }

  a:visited {
    color: #000;
  }
}

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 22px;
  left: 37px;
  width: 4px;
  height: 10px;
  border-radius: 30%;
  background: #ffdb3b;
  animation: lds-spinner-bk 4s linear infinite;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes lds-spinner-bk {
  0% {
    background: #ffdb3b;
  }
  30% {
    background: #00bff0;
  }
  60% {
    background: #ed0034;
  }
  100% {
    background: #ffdb3b;
  }
}
</style>
