<template>
  <div class="contribution-form shabda-form">
    <component v-show="!preview && !loading"
      :is="contributionComponent"
      :key="contribution.id"
      :contribution="contribution"
      @send="preview = true"
    />

    <Loading v-if="loading" :loading="loading" msg="loading" />

    <div v-if="errorMsg" class="error">
      <p>
        Sorry we could not complete the submission at this time:
      </p>
      <ul v-if="errorMsg.constructor == Array">
        <li v-for="msg in errorMsg" :key="msg">{{ msg }}</li>
      </ul>
      <div v-else>{{ errorMsg }}</div>
    </div>

    <template v-if="preview && !loading">
      <button @click.prevent="preview = false" class="btn">
        <font-awesome-icon class="icon" :icon="['fal', 'pencil-alt']" />
        edit contribution
      </button>

      <component :is="contributionPreviewComponent"
        :key="contribution.id"
        :contribution="{ contributionItem : formData }"
        :showStatus="false"
        @send="preview = true"
      />

      <p>
        <i>Please check the contribution above before submitting.</i>
        <br />
        <button type="submit" class="btn btn-action" @click="send">submit contribution</button>
      </p>
    </template>
  </div>
</template>

<script>
import store from '../../store/shabda.js'
import shabdaApi from '../../api/shabda-api.js'
import Loading from '../Loading.vue'
import LetterForm from './LetterForm.vue'
import NoticeForm from './NoticeForm.vue'
import EulogyForm from './EulogyForm.vue'
import ShabdaLetterContribution from '../organisms/ShabdaLetterContribution.vue'
import ShabdaNoticeContribution from '../organisms/ShabdaNoticeContribution.vue'
import ShabdaEulogyContribution from '../organisms/ShabdaEulogyContribution.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPencilAlt } from '@fortawesome/pro-light-svg-icons'

library.add(faPencilAlt)

export default {
  components: {
    LetterForm,
    NoticeForm,
    EulogyForm,
    ShabdaLetterContribution,
    ShabdaNoticeContribution,
    ShabdaEulogyContribution,
    Loading
  },
  props: {
    contribution: Object
  },
  data() {
    return {
      preview: false,
      errorMsg: null,
      loading: false
    }
  },
  created() {
    store.loadFormData(this.contribution)
  },
  computed: {
    formData() {
      return store.contributionForm
    },
    contributionComponent() {
      if (this.contribution.contributionItem.type === 'letter') {
        return 'LetterForm'
      }

      if (this.contribution.contributionItem.type === 'notice') {
        return 'NoticeForm'
      }

      if (this.contribution.contributionItem.type === 'eulogy') {
        return 'EulogyForm'
      }

      return ''
    },
    contributionPreviewComponent() {
      if (this.contribution.contributionItem.type === 'letter') {
        return 'ShabdaLetterContribution'
      }

      if (this.contribution.contributionItem.type === 'notice') {
        return 'ShabdaNoticeContribution'
      }

      if (this.contribution.contributionItem.type === 'eulogy') {
        return 'ShabdaEulogyContribution'
      }

      return ''
    }
  },
  methods: {
    send() {
      this.loading = true
      this.errorMsg = null

      shabdaApi.contribute(store.contributionForm)
        .then(data => {
          store.clear()
          store.clearEditContribution()
          store.fetchContributions()
          this.preview = false
        })
        .catch(err => {
          this.errorMsg = err.errors || err.msg || err
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped lang="scss">
.contribution-form {
  margin: 2rem 0;
}
</style>
