export const wordCount = (htmlString) => {
  let textString = removeHTMLTags(htmlString)
  let matches = textString.match(/\S+/g)

  return {
    characters: textString.length,
    words: matches ? matches.length : 0
  }
}

export const removeHTMLTags = (htmlString) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlString, 'text/html')
  // Extract the text content from the parsed document
  const textContent = doc.body.textContent || ''
  return textContent.trim()
}
