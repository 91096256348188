import utils from './utils'
import { ajaxRequest } from '../utils/http.js'

const entity = 'keystones'

export default {
  fetch (id) {
    const url = '/v1/' + entity + '/' + id
    return ajaxRequest(url, 'get').promise
  },
  fetchPageByUrlSlug (id, slug) {
    const url = '/v1/' + entity + '/' + id + '/pageByUrlSlug' + utils.buildQueryString({ slug: slug })
    return ajaxRequest(url, 'get').promise
  },
  fetchAll (start, limit, query) {
    const data = { limit: limit || 20, page: start || 0, q: query }
    const url = '/v1/' + entity + utils.buildQueryString(data)
    return ajaxRequest(url, 'get').promise
  },
  create (data) {
    const url = '/v1/' + entity
    return ajaxRequest(url, 'post', data).promise
  },
  update (data) {
    const url = '/v1/' + entity + '/' + encodeURIComponent(data.id)
    return ajaxRequest(url, 'put', data).promise
  },
  remove (data) {
    const url = '/v1/' + entity + '/' + encodeURIComponent(data.id)
    return ajaxRequest(url, 'delete', data).promise
  }
}
