<template>
    <div class="page-select-controls">
      <div v-if="noOfPages > 1 && currentPage != 1">
        <router-link
          v-if="useRouter"
          :class="{ 'page-no': true }"
          :to="{ name: routeName, query: params(currentPage - 1) }"
        >prev</router-link>
        <a v-if="!useRouter"
          href="#prev"
          :class="{ 'page-no': true }"
          @click.prevent="selectPage(currentPage - 1)"
        >prev</a>
      </div>

      <div v-if="showPrevHint">
        <router-link
          v-if="useRouter"
          :class="{ 'page-no': true }"
          :to="{ name: routeName, query: params(1) }"
        >1</router-link>
        <a v-if="!useRouter"
          href="#first"
          :class="{ 'page-no': true }"
          @click.prevent="selectPage(1)"
        >1</a>
      </div>

      <div v-if="showPrevHint">...</div>

      <template v-if="useRouter && noOfPages > 1">
      <router-link
        v-for="no in pagesToDisplay"
        :key="no"
        :class="{ 'page-no': true, selected: pageNoSelected(no) }"
        :to="{ name: routeName, query: params(no) }"
      >{{ no }}</router-link>
      </template>
      <template v-if="!useRouter && noOfPages > 1">
      <a href="#pages"
        v-for="no in pagesToDisplay"
        :key="no"
        :class="{ 'page-no': true, selected: pageNoSelected(no) }"
        @click.prevent="selectPage(no)"
      >{{ no }}</a>
      </template>

      <div v-if="showNextHint">...</div>

      <div v-if="showNextHint">
        <router-link
          v-if="useRouter"
          :class="{ 'page-no': true }"
          :to="{ name: routeName, query: params(noOfPages) }"
          >{{ noOfPages }}</router-link>
        <a v-if="!useRouter"
          href="#last"
          :class="{ 'page-no': true }"
          @click.prevent="selectPage(noOfPages)"
          >{{ noOfPages }}</a>
      </div>

      <div v-if="noOfPages > 1 && currentPage != noOfPages">
        <router-link
          v-if="useRouter"
          :class="{ 'page-no': true }"
          :to="{ name: routeName, query: params(currentPage + 1) }"
        >next</router-link>
        <a v-if="!useRouter"
          href="#prev"
          :class="{ 'page-no': true }"
          @click.prevent="selectPage(currentPage + 1)"
        >next</a>
      </div>
    </div>
</template>

<script>
const pagePadding = 2

export default {
  props: {
    noOfPages: {
      type: Number,
      default: 1
    },
    currentPage: {
      type: Number,
      default: 1
    },
    useRouter: {
      type: Boolean,
      default: false
    },
    routeName: {
      type: String,
      default: ''
    },
    queryParams: {
      type: [Object, null],
      default() {
        return null
      }
    }
  },
  data() {
    return {
      showPrevHint: false,
      showNextHint: false
    }
  },
  created () {
    if (this.currentPage > (pagePadding + 2)) {
      this.showPrevHint = true
    }

    if (this.noOfPages > (this.currentPage + (pagePadding + 1))) {
      this.showNextHint = true
    }
  },
  computed: {
    pagesToDisplay () {
      let start = 1
      let end = this.noOfPages
      let pages = []

      if (this.currentPage > (pagePadding + 2)) {
        start = this.currentPage - pagePadding
      }

      if (this.noOfPages > (this.currentPage + (pagePadding + 1))) {
        end = this.currentPage + pagePadding
      }

      for (var i = start; i <= end; i++) {
        pages.push(i)
      }

      return pages
    }
  },
  methods: {
    params (pageNumber) {
      if (this.queryParams) {
        let params = Object.assign({}, this.queryParams)
        params.page = pageNumber
        return params
      }

      return {
        page: pageNumber
      }
    },
    pageNoSelected (pageNumber) {
      return this.currentPage === pageNumber
    },
    selectPage (pageNumber) {
      this.$emit('pageSelected', pageNumber)
    }
  }
}
</script>

<style scoped lang="scss">
.page-no {
  display: inline-block;
  border: 1px solid #999;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  margin: 0 0.4rem;
  color: #999;
  text-decoration: none;

  &:hover {
    background-color: #999;
    color: #fff;
  }

  &.selected {
    background-color: #999;
    color: #fff;
  }
}

.page-select-controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1rem 0 2rem 0;
}
</style>
