import { render, staticRenderFns } from "./ShabdaNoticeContribution.vue?vue&type=template&id=86867daa&scoped=true&"
import script from "./ShabdaNoticeContribution.vue?vue&type=script&lang=js&"
export * from "./ShabdaNoticeContribution.vue?vue&type=script&lang=js&"
import style0 from "./ShabdaNoticeContribution.vue?vue&type=style&index=0&id=86867daa&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86867daa",
  null
  
)

export default component.exports