import utils from './utils'
import { ajaxRequest } from '../utils/http.js'

const entity = 'shabda'

export default {
  fetchAll(type = 'letters', pageNo, limit = 10, criteria = null) {
    const data = { type: type, limit: limit, pageNo: pageNo || 1 }
    if (criteria) {
      data.criteria = criteria
    }
    const url = '/v1/' + entity + utils.buildQueryString(data)
    return ajaxRequest(url, 'get').promise
  },
  fetchCurrentIssue() {
    const url = '/v1/' + entity + '/current-issue'
    return ajaxRequest(url, 'get').promise
  },
  contribute(data) {
    const url = '/v1/' + entity
    return ajaxRequest(url, 'post', data).promise
  },
  remove(data) {
    const url = '/v1/' + entity + '/' + data.contributionItem.id
    return ajaxRequest(url, 'delete', data).promise
  }
}
