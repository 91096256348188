export function updateURL(params, url) {
  let qp = new URLSearchParams()

  for (let prop in params) {
    qp.set(prop, params[prop])
  }

  history.replaceState(null, null, (url || '') + '?' + qp.toString())
}

const pageData = {
  heroImage: {
    desktop: 'sites/all/themes/tbc/images/nbco/tbco-hero-image.jpg',
    tablet: 'sites/all/themes/tbc/images/nbco/tbco-hero-image.jpg',
    mobile: 'sites/all/themes/tbc/images/nbco/tbco-hero-image.jpg'
  }
}

export function fetchPageData() {
  if (document.__TBCO__.heroImage) {
    pageData.heroImage = document.__TBCO__.heroImage
  }

  return pageData
}
