import utils from './utils'
import { ajaxRequest } from '../utils/http.js'

const entity = 'media'

export function isImageMime(mimeType) {
  const imageMimeTypes = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/gif'
  ]

  return imageMimeTypes.indexOf(mimeType) !== -1
}

export default {
  fetchAll (pageNo, limit = 10, criteria = null) {
    const data = { limit: limit || 25, pageNo: pageNo || 1 }
    if (criteria) {
      data.criteria = criteria
    }
    const url = '/v1/' + entity + utils.buildQueryString(data)
    return ajaxRequest(url, 'get').promise
  },
  save (id, data) {
    const url = '/v1/' + entity + '/' + id
    return ajaxRequest(url, 'post', data).promise
  },
  delete (id) {
    const url = '/v1/' + entity + '/' + id
    return ajaxRequest(url, 'delete').promise
  },
  generateImages (media) {
    const url = '/v1/' + entity + '/' + media.id + '/generate-images'
    return ajaxRequest(url, 'post', media).promise
  }
}
