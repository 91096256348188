<template>
  <div class="page-content">
    <p>By <button type="button" @click="listType = 'country'">country</button> or <button type="button" @click="listType = 'type'">type</button></p>
    <template v-if="listType === 'country'">
    <h2>By country</h2>

    <Loading v-if="loading" :loading="loading" msg="loading" />

    <p v-if="selectedCountry"><span class="label-selected" v-html="selectedCountry"></span> <a @click.prevent="selectedCountry = null">back to all countries</a></p>
    <ul v-if="!selectedCountry" class="community-links">
      <li v-for="(link, country) in countries" :key="country"><a v-html="country" @click="selectedCountry = country"></a></li>
    </ul>

    <ul v-else class="community-links">
      <li v-for="(link, index) in countries[selectedCountry]" :key="index">
        <p v-html="index"></p>
        <ul>
          <li v-for="(l, index) in link" :key="index" v-html="l"></li>
        </ul>
      </li>
    </ul>
    </template>

    <template v-if="listType === 'type'">
    <h2>By type</h2>

    <Loading v-if="loading" :loading="loading" msg="loading" />

    <p v-if="selectedType"><span class="label-selected" v-html="selectedType"></span> <a @click.prevent="selectedType = null">back to all types</a></p>
    <ul v-if="!selectedType" class="community-links">
      <li v-for="(link, type) in types" :key="type"><a v-html="type" @click="selectedType = type"></a></li>
    </ul>

    <ul v-else class="community-links">
      <li v-for="(link, index) in types[selectedType]" :key="index">
        <p v-html="index"></p>
        <ul>
          <li v-for="(l, index) in link" :key="index" v-html="l"></li>
        </ul>
      </li>
    </ul>
    </template>
  </div>
</template>

<script>
import axios from 'axios'
import Loading from './Loading.vue'

export default {
  components: {
    Loading
  },
  data() {
    return {
      listType: 'country',
      loading: true,
      countries: [],
      types: [],
      selectedCountry: null,
      selectedType: null
    }
  },
  created() {
    axios.get('/v1/community-links')
      .then(response => {
        this.countries = response.data['community-links'].countries
        this.types = response.data['community-links'].types
      }).catch(() => {
        console.error('Could not fetch community links')
      })
      .finally(() => {
        this.loading = false
      })
  }
}
</script>

<style scoped lang="scss">
.community-links {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  * {
    flex-basis: 30%;

    @media screen and (max-width: 1280px) {
      flex-basis: 30%;
    }

    @media screen and (max-width: 1000px) {
      flex-basis: 48%;
    }

    @media screen and (max-width: 700px) {
      flex-basis: 100%;
    }
  }
}

h2 {
  margin: 2rem 0 1rem;
}

a {
  text-decoration: underline;
  cursor: pointer;
}

.label-selected {
  margin-right: 0.5rem;
}

p {
  margin: 1rem 0;
}

.page-content {
  margin-bottom: 2rem;
}

button {
  background: none;
  border: none;
  text-decoration: underline;
  outline: none;
  cursor: pointer;
}
</style>
