var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex"},[_c('ValidationObserver',{ref:"form"},[_c('form',{ref:"contributeForm",staticClass:"contribute-form",attrs:{"method":"POST"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.id),expression:"formData.id"}],attrs:{"type":"hidden","name":"id"},domProps:{"value":(_vm.formData.id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "id", $event.target.value)}}}),_c('div',{staticClass:"form_input"},[_c('label',[_vm._v("Date")]),_c('ValidationProvider',{attrs:{"name":"contribution_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DatePicker',{attrs:{"name":"publication_date","valueType":"format","format":"DD/MM/YYYY","placeholder":"DD/MM/YYYY"},on:{"input":_vm.persist},model:{value:(_vm.formData.contribution_date),callback:function ($$v) {_vm.$set(_vm.formData, "contribution_date", $$v)},expression:"formData.contribution_date"}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.contribution_date),expression:"formData.contribution_date"}],attrs:{"type":"hidden","name":"contribution_date"},domProps:{"value":(_vm.formData.contribution_date)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "contribution_date", $event.target.value)}}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"form_input"},[_c('label',[_vm._v("Location")]),_c('ValidationProvider',{attrs:{"name":"location","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.location),expression:"formData.location"}],attrs:{"name":"location","type":"text","placeholder":"i.e Rome or Bristol Women's Community"},domProps:{"value":(_vm.formData.location)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "location", $event.target.value)},_vm.persist]}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('ValidationProvider',{attrs:{"name":"content","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form_input"},[_c('label',{staticClass:"sr-only"},[_vm._v("Content")]),_c('Editor',{on:{"input":_vm.persist},model:{value:(_vm.formData.content),callback:function ($$v) {_vm.$set(_vm.formData, "content", $$v)},expression:"formData.content"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('p',[_vm._v("You have submitted "+_vm._s(_vm.wordCount(_vm.formData.content).words)+" words")]),(_vm.showAdminOptions)?_c('div',{staticClass:"admin-options"},[_c('h3',{staticClass:"title"},[_vm._v("Admin options")]),_c('div',{staticClass:"form_input"},[_c('label',[_vm._v("Author")]),_c('p',[_vm._v("Choose an author for this contribution.")]),_c('ValidationProvider',{attrs:{"name":"author","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form_author),expression:"form_author"}],attrs:{"type":"text","name":"author","placeholder":"Enter ordername"},domProps:{"value":(_vm.form_author)},on:{"input":function($event){if($event.target.composing){ return; }_vm.form_author=$event.target.value}}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,false,2954187623)})],1)]):_vm._e(),_c('p',[_c('button',{staticClass:"btn btn-success btn-action",attrs:{"type":"submit"}},[_vm._v("preview and submit")])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }