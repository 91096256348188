<template>
  <div class="resource-table-container">
    <table class="resource-table">
      <thead>
        <tr>
          <th>Filename</th>
          <th>Filesize</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(resource, index) in resources" :key="index" :class="{ 'odd-row': index % 2 === 0, 'even-row': index % 2 !== 0 }">
          <td>
            <a :href="resource.source" target="_blank" class="resource-link">
              <span class="file-icon">
                <font-awesome-icon class="icon" :icon="getFileIcon(resource.mimeType)" />
              </span>
              <span class="resource-description">
                {{ (resource.description) ? resource.description : resource.filename }}
              </span>
            </a>
          </td>
          <td>{{ getFileSizeKB(resource.metadata.filesize) }} KB</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFilePdf, faFileWord, faFile } from '@fortawesome/pro-solid-svg-icons'

library.add(faFilePdf, faFileWord, faFile)

export default {
  props: {
    label: String,
    btnType: {
      type: String,
      default: ''
    },
    loading: Boolean,
    styles: {
      type: String,
      default: ''
    },
    resources: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getFileSizeKB(filesize) {
      return Math.round(parseInt(filesize) / 1024)
    },
    getFileIcon(mimeType) {
      if (mimeType.includes('pdf')) {
        return faFilePdf
      } else if (mimeType.includes('word')) {
        return faFileWord
      } else {
        return faFile
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@dharmachakra/tbco-shared-styles/src/assets/scss/partials/_variables.scss';
.resource-link {
  display: flex;
  align-items: center;
}

.resource-description {
  margin-left: 10px;
}

.resource-table-container {
  max-width: 100%;
  overflow-x: auto; /* Add horizontal scrollbar if content exceeds viewport width */
  margin: 0 auto;
}

.resource-table {
  border-collapse: collapse;
  margin-top: 10px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 20px;
  max-width: 100%; /* Make the table take up the full container width */
  @media screen and (max-width: $mobile-max-size) {
        font-size: 16px;
        letter-spacing: 0.39px;
        line-height: 22.5px;
        h1 {
            font-size: 16px;
        }
    }
}

.resource-table th,
.resource-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.odd-row {
  background-color: #f1f1f1;
}

.even-row {
  background-color: #ffffff;
}

.file-icon {
  margin-right: 5px;
}
</style>
