<template>
  <section class="system-messages" v-if="messages.length">
    <div v-for="(message, index) in messages" :key="index" :class="'alert-' + message.status" class="alert alert-dismissible" role="alert">
      <button @click.prevent="clearMessages" type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      <strong v-if="message.title">{{ message.title }}</strong> {{ message.message }}
    </div>
  </section>
</template>

<script>
export default {
  props: {
    messages: Array
  },
  methods: {
    clearMessages() {
      this.$emit('clearSystemAlerts')
    }
  }
}
</script>

<style scoped lang="scss">
.system-messages {
  position: fixed;
  width: 80vw;
  top: 0;
  left: 50%;
  margin-left: -40vw;
  z-index: 99999;
}
</style>
