<template>
  <div>
    <template v-if="store.state.editingForm">
      <button @click.prevent="store.clearEditContribution()" class="btn">
        &#8617; back to my contributions
      </button>

      <ContributionForm
        :contribution="store.state.selectedContribution"
      />
    </template>

    <template v-else>
    <h2><font-awesome-icon :icon="['fal', 'envelope']" /> Letter reports</h2>
    <template v-if="!state.loadingLetters">
      <p v-if="!state.letters.length">You have no contributions.</p>
      <ShabdaContribution v-for="contribution in state.letters"
                          :key="contribution.id"
                          :contribution="contribution"
                          @withdrawn="store.fetchLetters"
      />
    </template>
    <font-awesome-icon v-if="state.loadingLetters" class="fa-pulse" icon="spinner" />

    <h2><font-awesome-icon :icon="['fal', 'flag-alt']" /> Notices</h2>
    <template v-if="!state.loadingNotices">
      <p v-if="!state.notices.length">You have no notices.</p>
      <ShabdaContribution v-for="contribution in state.notices"
                          :key="contribution.id"
                          :contribution="contribution"
                          @withdrawn="store.fetchNotices"
      />
    </template>
    <font-awesome-icon v-if="state.loadingNotices" class="fa-pulse" icon="spinner" />

    <h2><font-awesome-icon :icon="['fal', 'praying-hands']" /> Eulogies</h2>
    <template v-if="!state.loadingEulogies">
      <p v-if="!state.eulogies.length">You have no eulogies.</p>
      <ShabdaContribution v-for="contribution in state.eulogies"
                          :key="contribution.id"
                          :contribution="contribution"
                          @withdrawn="store.fetchEulogies"
      />
    </template>
    <font-awesome-icon v-if="state.loadingEulogies" class="fa-pulse" icon="spinner" />
    </template>

  </div>
</template>

<script>
import store from '../../store/shabda.js'
import ShabdaContribution from './ShabdaContribution.vue'
import ContributionForm from '../forms/ContributionForm.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope, faFlagAlt, faPrayingHands, faTimes } from '@fortawesome/pro-light-svg-icons'

library.add(faSpinner, faEnvelope, faFlagAlt, faPrayingHands, faTimes)

export default {
  components: {
    ShabdaContribution,
    ContributionForm
  },
  created() {
    store.fetchContributions()
  },
  computed: {
    state: () => store.state,
    store: () => store
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  p {
    margin-bottom: 0.75rem;
  }

  h2 {
    margin: 2.5rem 0 1.5rem 0;
  }

  h3 {
    margin-bottom: 1.25rem 0;
  }
}

h2 {
  margin: 2.5rem 0 1.5rem 0;
  padding-bottom: 0.25rem;
  border-bottom: 2px solid #eee;
}
</style>
