import Vue from 'vue'

const state = Vue.observable({
  messages: []
})

export default {
  addMessage(status, msg, clear = false) {
    state.messages.push({
      status: status,
      message: msg
    })

    if (clear) {
      setTimeout(() => {
        this.clearMessages()
      }, 1500)
    }
  },
  clearMessages() {
    state.messages = []
  },
  messages() {
    return state.messages
  },
  appData() {
    return document.__TBCO__ || {}
  }
}
