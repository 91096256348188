import utils from './utils'
import { ajaxRequest } from '../utils/http.js'

const entity = 'channels'

export default {
  fetch (id) {
    const url = '/v2/' + entity + '/' + id
    return ajaxRequest(url, 'get').promise
  },
  fetchAll (start, limit, query) {
    const data = { limit: limit || 20, page: start || 0, q: query }
    const url = '/v1/' + entity + utils.buildQueryString(data)
    return ajaxRequest(url, 'get').promise
  },
  fetchChannelPosts (id, start, limit, filter, query, display, terms) {
    const data = { limit: limit || 20, start: start || 0, f: filter, q: query }

    if (display) {
      data.display = display
    }

    if (terms) {
      data.terms = terms
    }

    const url = '/v1/' + entity + '/' + id + utils.buildQueryString(data)
    return ajaxRequest(url, 'get').promise
  },
  update (data) {
    // @todo - complete the server side routing/logic
    const url = '/v1/' + entity + '/' + encodeURIComponent(data.id)
    return ajaxRequest(url, 'put', data).promise
  }
}
