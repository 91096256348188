<template>
  <div>
    <div class="c-post-title-section__title">
      <a v-if="titleurl" :href="titleurl">{{ title }}</a>
      <div v-else>{{ title }}</div>
      <OmMark v-if="isOm" />
      <a v-if="canEdit" :href="editLink" title="edit this post" class="c-post-title-edit-link">[edit]</a>
    </div>
    <div class="c-post-title-section__subtitle">{{subtitle}}</div>
    <div class="c-post-title-section__author-date">by {{author}}, {{ date }}<span v-if="shareButton === true"><span class="c-post-title-section__divider">|</span><ShareButton/></span></div>
  </div>
</template>

<script>
import ShareButton from '@dharmachakra/tbco-shared-styles/src/components/ShareButton.vue'
import OmMark from '@dharmachakra/tbco-shared-styles/src/components/atoms/OmMark.vue'

export default {
  components: {
    ShareButton,
    OmMark
  },
  props: {
    title: {
      type: String
    },
    titleurl: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String
    },
    author: {
      type: String
    },
    date: {
      type: String
    },
    canEdit: Boolean,
    editLink: String,
    shareButton: {
      type: Boolean,
      default: false
    },
    isOm: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
@import '@dharmachakra/tbco-shared-styles/src/assets/scss/partials/_variables.scss';

  .c-post-title-section__title {
    color: rgba(0,0,0,0.85);
    font-family: $font-family-open-sans;
    font-size: 30px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0.94px;
    text-align: left;
    line-height: 42px;
    margin-bottom: 2px;
    a {
      text-decoration: none;
      color: rgba(0,0,0,0.85);
    }
    @media screen and (max-width: 768px) {
        font-size: 26px;
        font-weight: 700;
        letter-spacing: 0.81px;
        line-height: 34px;
        margin-bottom: 8px;
    }
    @media screen and (max-width: $mobile-max-size) {
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.5px;
        line-height: 23px;
        margin-bottom: 4px;
    }
  }

  .c-post-title-section__subtitle {
    color: rgba(0,0,0,0.85);
    font-family: $font-family-open-sans;
    font-size: 22px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0.69px;
    text-align: left;
    line-height: 42px;
    @media screen and (max-width: 768px) {
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0.63px;
        line-height: 20px;
        margin-bottom: 8px;
    }
    @media screen and (max-width: $mobile-max-size) {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.5px;
        line-height: 15px;
        margin-bottom: 4px;
    }
  }

  .c-post-title-section__author-date {
    opacity: 1;
    color: rgba(0,0,0,1);
    font-family: $font-family-open-sans;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 0.65px;
    text-align: left;
    line-height: 42px;
    display: flex;
    @media screen and (max-width: $tablet-max-size) {
        font-size: 20px;
        font-weight: 400;
        letter-spacing: 0.59px;
        line-height: 34px;
    }
    @media screen and (max-width: $mobile-max-size) {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.47px;
        line-height: 20px;
    }
    .c-post-title-section__divider {
        margin-left: 17px;
        margin-right: 17px;
    }
  }

.c-post-title-edit-link {
  font-size: 1.25rem;
  margin-left: 0.3rem;
  display: inline-block;

  &:hover {
    text-decoration: underline;
  }

  @media screen and (max-width: $tablet-max-size) {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0.59px;
    line-height: 34px;
  }

  @media screen and (max-width: $mobile-max-size) {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.47px;
    line-height: 20px;
  }
}
</style>
