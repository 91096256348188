<template>
  <div v-if="editor" class="editor">
    <button type="button" @click="editor.chain().focus().toggleBold().run()" :disabled="!editor.can().chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
      <font-awesome-icon :icon="['far', 'bold']" />
        <span class="sr-only">bold</span>
    </button>
    <button type="button" @click="editor.chain().focus().toggleItalic().run()" :disabled="!editor.can().chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
      <font-awesome-icon :icon="['far', 'italic']" />
        <span class="sr-only">italic</span>
    </button>
    <button type="button" @click="editor.chain().focus().toggleStrike().run()" :disabled="!editor.can().chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
      <font-awesome-icon :icon="['far', 'strikethrough']" />
        <span class="sr-only">strike</span>
    </button>
    <button type="button" @click="editor.chain().focus().toggleUnderline().run()" :disabled="!editor.can().chain().focus().toggleUnderline().run()" :class="{ 'is-active': editor.isActive('underline') }">
      <font-awesome-icon :icon="['far', 'underline']" />
        <span class="sr-only">underline</span>
    </button>

    <button v-if="allowCode" type="button" @click="editor.chain().focus().toggleCode().run()" :disabled="!editor.can().chain().focus().toggleCode().run()" :class="{ 'is-active': editor.isActive('code') }">
      code
    </button>

    <button v-if="allowMarks" type="button" @click="editor.chain().focus().unsetAllMarks().run()">
      clear marks
    </button>
    <button v-if="allowNodes" type="button" @click="editor.chain().focus().clearNodes().run()">
      clear nodes
    </button>

    <button type="button" @click="editor.chain().focus().setParagraph().run()" :class="{ 'is-active': editor.isActive('paragraph') }">
      <font-awesome-icon :icon="['far', 'paragraph']" />
        <span class="sr-only">paragraph</span>
    </button>

    <template v-if="allowHeadings">
      <button type="button" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
        <font-awesome-icon :icon="['far', 'h1']" />
          <span class="sr-only">h1</span>
      </button>
      <button type="button" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
        <font-awesome-icon :icon="['far', 'h2']" />
          <span class="sr-only">h2</span>
      </button>
      <button type="button" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
        <font-awesome-icon :icon="['far', 'h3']" />
          <span class="sr-only">h3</span>
      </button>
      <button type="button" @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }">
        <font-awesome-icon :icon="['far', 'h4']" />
          <span class="sr-only">h4</span>
      </button>
      <button type="button" @click="editor.chain().focus().toggleHeading({ level: 5 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }">
        h5
      </button>
      <button type="button" @click="editor.chain().focus().toggleHeading({ level: 6 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }">
        h6
      </button>
    </template>

    <button v-if="allowList" type="button" @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
      <font-awesome-icon :icon="['far', 'list-ul']" />
        <span class="sr-only">bullet list</span>
    </button>
    <button v-if="allowList" type="button" @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
      <font-awesome-icon :icon="['far', 'list-ol']" />
        <span class="sr-only">ordered list</span>
    </button>
    <button v-if="allowCode" type="button" @click="editor.chain().focus().toggleCodeBlock().run()" :class="{ 'is-active': editor.isActive('codeBlock') }">
      <font-awesome-icon :icon="['far', 'code']" />
        <span class="sr-only">code block</span>
    </button>
    <button v-if="allowQuote" type="button" @click="editor.chain().focus().toggleBlockquote().run()" :class="{ 'is-active': editor.isActive('blockquote') }">
      <font-awesome-icon :icon="['far', 'quote-right']" />
        <span class="sr-only">blockquote</span>
    </button>
    <button v-if="allowRule" type="button" @click="editor.chain().focus().setHorizontalRule().run()">
      <font-awesome-icon :icon="['far', 'horizontal-rule']" />
        <span class="sr-only">horizontal rule</span>
    </button>

    <button v-if="allowHardBreak" type="button" @click="editor.chain().focus().setHardBreak().run()">
      hard break
    </button>

    <button type="button" @click="setLink" :class="{ 'is-active': editor.isActive('link') }">
      <font-awesome-icon :icon="['far', 'link']" />
        <span class="sr-only">set link</span>
    </button>
    <button type="button" @click="editor.chain().focus().unsetLink().run()" :disabled="!editor.isActive('link')">
      <font-awesome-icon :icon="['far', 'unlink']" />
        <span class="sr-only">unset link</span>
    </button>

    <button type="button" @click="editor.chain().focus().undo().run()" :disabled="!editor.can().chain().focus().undo().run()">
      <font-awesome-icon :icon="['far', 'undo']" />
        <span class="sr-only">undo</span>
    </button>
    <button type="button" @click="editor.chain().focus().redo().run()" :disabled="!editor.can().chain().focus().redo().run()">
      <font-awesome-icon :icon="['far', 'redo']" />
        <span class="sr-only">redo</span>
    </button>
    <editor-content :editor="editor" />
  </div>
</template>

<script>
import Vue from 'vue'
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import Link from '@tiptap/extension-link'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faItalic, faBold, faStrikethrough, faUnderline, faLink, faUnlink, faParagraph, faUndo, faRedo, faListUl, faListOl, faCode, faQuoteRight, faHorizontalRule, faH1, faH2, faH3, faH4 } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faItalic, faBold, faStrikethrough, faUnderline, faLink, faUnlink, faParagraph, faUndo, faRedo, faListUl, faListOl, faCode, faQuoteRight, faHorizontalRule, faH1, faH2, faH3, faH4)

Vue.component('font-awesome-icon', FontAwesomeIcon)

export default {
  components: {
    EditorContent
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      allowHeadings: false,
      allowList: true,
      allowQuote: false,
      allowRule: false,
      allowCode: false,
      allowMarks: false,
      allowNodes: false,
      allowHardBreak: false,
      editor: null
    }
  },
  watch: {
    value(value) {
      const isSame = this.editor.getHTML() === value
      if (isSame) {
        return
      }
      this.editor.commands.setContent(value, false)
    }
  },
  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
        Underline,
        Link.configure({
          openOnClick: false,
          defaultProtocol: 'https'
        })
      ],
      onUpdate: () => {
        this.$emit('input', this.editor.getHTML())
      }
    })
  },
  beforeDestroy() {
    this.editor.destroy()
  },
  methods: {
    setLink() {
      const previousUrl = this.editor.getAttributes('link').href
      const url = window.prompt('URL', previousUrl)

      // cancelled
      if (url === null) {
        return
      }

      // empty
      if (url === '') {
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run()

        return
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run()
    }
  }
}
</script>

<style lang="scss">
/* Basic editor styles */
.tiptap {
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;
  }

  border: 1px solid #ccc;
  box-sizing: border-box;
  line-height: 1.42;
  height: 100%;
  min-height: 200px;
  max-height: 400px;
  outline: none;
  overflow-y: auto;
  padding: 12px 15px;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.editor {
  button {
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
    padding: 0.4rem 0.5rem;
    color: #757575;
    background: transparent;
    border: none;
    cursor: pointer;
  }

  .is-active {
    background-color: #757575;
    color: #fff;
  }
}
</style>
