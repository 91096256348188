import utils from './utils'
import { ajaxRequest } from '../utils/http.js'

const entity = 'search'

export default {
  search(start, limit, query, params) {
    const data = Object.assign({ limit: limit || 20, page: start || 0, q: query }, params || {})
    const url = '/v1/' + entity + utils.buildQueryString(data)
    return ajaxRequest(url, 'get').promise
  }
}
